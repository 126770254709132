<template>
    <painel :titulo="titulo" icone="pi pi-user" :refreshFunction="obterParticipante" v-show="mostrarPartipanteDetalhar">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :participante="participante"></dados-gerais>
                </TabPanel>
                <TabPanel header="Integração Externa">
                    <integracao-externa :participante="participante"></integracao-externa>
                </TabPanel>
                <TabPanel header="Integração PF">
                    <integracao-interna-pf :participante="participante"></integracao-interna-pf>
                </TabPanel>
                <TabPanel header="Integração PJ">
                    <integracao-interna-pj :participante="participante"></integracao-interna-pj>
                </TabPanel>
                <TabPanel header="Integração Vínculo">
                    <integracao-interna-vinculo :participante="participante"></integracao-interna-vinculo>
                </TabPanel>
                <TabPanel header="Integração Evento">
                    <integracao-interna-evento :participante="participante"></integracao-interna-evento>
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="rotaRetorno"></btn-voltar>
        <btn-atualizar :participante="participante" v-if="mostrarBtnAtualizar"></btn-atualizar>
        <btn-reiniciar-integracao
            :participante="participante"
            tipo="PF"
            @atualizar="obterParticipanteAtualizado()"
            v-if="mostrarBtnReiniciarIntegracaoPf"
        ></btn-reiniciar-integracao>
        <btn-reiniciar-integracao
            :participante="participante"
            tipo="PJ"
            @atualizar="obterParticipanteAtualizado()"
            v-if="mostrarBtnReiniciarIntegracaoPj"
        ></btn-reiniciar-integracao>
        <btn-reiniciar-integracao-evento
            :participante="participante"
            @atualizar="obterParticipanteAtualizado()"
            v-if="mostrarBtnReiniciarIntegracaoEvento"
        ></btn-reiniciar-integracao-evento>
    </painel>
    <router-view :participante="participante" :titulo="titulo" @atualizar="obterParticipanteAtualizado()"></router-view>
</template>

<script>
import ParticipantesService from './services';
import DadosGerais from './DadosGerais';
import IntegracaoExterna from './IntegracaoExterna';
import IntegracaoInternaPf from './IntegracaoInternaPf';
import IntegracaoInternaPj from './IntegracaoInternaPj';
import IntegracaoInternaVinculo from './IntegracaoInternaVinculo';
import IntegracaoInternaEvento from './IntegracaoInternaEvento';
import BtnAtualizar from './BtnAtualizar';
import { StatusIntegracaoInterna, StatusIntegracaoExterna } from './ParticipanteEnums';
import BtnReiniciarIntegracao from './BtnReiniciarIntegracao';
import BtnReiniciarIntegracaoEvento from './BtnReiniciarIntegracaoEvento';

export default {
    props: {
        evento: {
            type: Object,
        },
    },

    components: {
        DadosGerais,
        IntegracaoExterna,
        IntegracaoInternaPf,
        IntegracaoInternaPj,
        IntegracaoInternaVinculo,
        IntegracaoInternaEvento,
        BtnAtualizar,
        BtnReiniciarIntegracao,
        BtnReiniciarIntegracaoEvento,
    },

    data() {
        return {
            participante: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterParticipante() {
            this.$store.dispatch('addRequest');
            ParticipantesService.obterPorId(this.$route.params.id, this.$route.params.participanteId).then((response) => {
                if (response?.success) {
                    this.participante = response.data;
                } else {
                    this.participante = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterParticipanteAtualizado() {
            this.$store.dispatch('setAtualizar', true);
            this.obterParticipante();
        },
    },

    computed: {
        rotaRetorno() {
            return {
                name: 'Checkins_Eventos_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            };
        },

        mostrarPartipanteDetalhar() {
            return this.$route.name == 'Checkins_Eventos_Participantes_Detalhar';
        },

        titulo() {
            if (this.evento) {
                return `Evento: ${this.evento?.codigo} - ${this.evento?.nome} | Participante: ${this.participante?.nome}`;
            }
            return 'Evento: | Participante:';
        },

        mostrarBtnAtualizar() {
            return (
                this.activeIndex == 0 &&
                this.$temAcessoView('CHECKINS-EVENTOS-07') &&
                (this.participante?.statusIntegracaoInternaPf == StatusIntegracaoInterna.ERRO_NA_INTEGRACAO ||
                    this.participante?.statusIntegracaoInternaPf == StatusIntegracaoInterna.FALHA_NA_INTEGRACAO ||
                    this.participante?.statusIntegracaoExterna == StatusIntegracaoExterna.INVALIDO)
            );
        },

        mostrarBtnReiniciarIntegracaoPf() {
            return (
                this.activeIndex == 2 &&
                this.$temAcessoView('CHECKINS-EVENTOS-08') &&
                this.participante?.statusIntegracaoInternaPf == StatusIntegracaoInterna.ERRO_NA_INTEGRACAO
            );
        },

        mostrarBtnReiniciarIntegracaoPj() {
            return (
                this.activeIndex == 3 &&
                this.$temAcessoView('CHECKINS-EVENTOS-08') &&
                this.participante?.statusIntegracaoInternaPj == StatusIntegracaoInterna.ERRO_NA_INTEGRACAO
            );
        },

        mostrarBtnReiniciarIntegracaoEvento() {
            return (
                this.activeIndex == 5 &&
                this.$temAcessoView('CHECKINS-EVENTOS-08') &&
                this.participante?.statusIntegracaoInternaEvento == StatusIntegracaoInterna.ERRO_NA_INTEGRACAO
            );
        },
    },

    mounted() {
        this.obterParticipante();
    },
};
</script>
