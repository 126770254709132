<template>
    <Button label="Reiniciar Integração" icon="pi pi-refresh" @click="confirmar()" class="ml-2" />
</template>

<script>
import ParticipantesServices from './services';

export default {
    props: {
        participante: {
            type: Object,
        },
    },

    emits: ['atualizar'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja reiniciar a integração do participante com o evento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.reiniciarIntegracao();
                },
            });
        },

        reiniciarIntegracao() {
            this.$store.dispatch('addRequest');
            ParticipantesServices.reiniciarIntegracaoEvento(this.$route.params.id, this.participante.participanteId)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Participante',
                            detail: 'Participante atualizado com sucesso',
                            life: 3000,
                        });
                        this.$emit('atualizar');
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Participante',
                            detail: response.errors[0],
                            life: 3000,
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
