<template>
    <detalhe titulo="Situação" :size="size">
        <status :status="participante?.statusIntegracaoExternaDescr"></status>
    </detalhe>
    <detalhe titulo="Observações" :size="size">
        {{ participante?.observacoesIntegracaoExterna }}
    </detalhe>
    <detalhe titulo="Objeto Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoRetornoIntegracaoExterna)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoRetornoIntegracaoExterna && participante?.objetoRetornoIntegracaoExterna?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoRetornoIntegracaoExterna && !participante?.objetoRetornoIntegracaoExterna?.isJson()">{{
            participante?.objetoRetornoIntegracaoExterna
        }}</span>
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        participante: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
