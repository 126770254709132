<template>
    <detalhe titulo="Status" :size="size">
        <status :status="participante?.statusIntegracaoInternaEventoDescr"></status>
    </detalhe>
    <detalhe titulo="Tentativas Integração" :size="size">
        {{ participante?.tentativaIntegracaoInternaEvento }}
    </detalhe>
    <detalhe titulo="Data Integração" :size="size">
        {{ $dateFormat(participante?.dataIntegracaoInternaEvento, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Objeto Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoIntegracaoInternaEvento)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoIntegracaoInternaEvento && participante?.objetoIntegracaoInternaEvento?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoIntegracaoInternaEvento && !participante?.objetoIntegracaoInternaEvento?.isJson()">{{
            participante?.objetoIntegracaoInternaEvento
        }}</span>
    </detalhe>
    <detalhe titulo="Retorno Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoRetornoIntegracaoInternaEvento)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoRetornoIntegracaoInternaEvento && participante?.objetoRetornoIntegracaoInternaEvento?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoRetornoIntegracaoInternaEvento && !participante?.objetoRetornoIntegracaoInternaEvento?.isJson()">
            {{ participante?.objetoRetornoIntegracaoInternaEvento }}</span
        >
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        participante: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
