<template>
    <detalhe titulo="Status" :size="size">
        <status :status="participante?.statusIntegracaoInternaPjDescr"></status>
    </detalhe>
    <detalhe titulo="Código" :size="size">
        {{ participante?.codigoInternoPj }}
    </detalhe>
    <detalhe titulo="Tentativas Integração" :size="size">
        {{ participante?.tentativaIntegracaoInternaPj }}
    </detalhe>
    <detalhe titulo="Observações" :size="size">
        {{ participante?.observacoesIntegracaoInternaPj }}
    </detalhe>
    <detalhe titulo="Data Integração" :size="size">
        {{ $dateFormat(participante?.dataIntegracaoInternaPj, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Objeto Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoIntegracaoInternaPj)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoIntegracaoInternaPj && participante?.objetoIntegracaoInternaPj?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoIntegracaoInternaPj && !participante?.objetoIntegracaoInternaPj?.isJson()">
            {{ participante?.objetoIntegracaoInternaPj }}</span
        >
    </detalhe>
    <detalhe titulo="Retorno Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoRetornoIntegracaoInternaPj)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoRetornoIntegracaoInternaPj && participante?.objetoRetornoIntegracaoInternaPj?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoRetornoIntegracaoInternaPj && !participante?.objetoRetornoIntegracaoInternaPj?.isJson()">
            {{ participante?.objetoRetornoIntegracaoInternaPj }}</span
        >
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        participante: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
