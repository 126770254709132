import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;
const urlBase = `${api}/eventos`;

export default {
    obterParticipantesEvento(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/participantes`);
    },

    obterPorId(eventoId, participanteId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/participantes/${participanteId}`);
    },

    atualizar(eventoId, participanteDto) {
        return axiosJwt.post(`${urlBase}/${eventoId}/participantes/${participanteDto.participanteId}/atualizar`, participanteDto);
    },

    reiniciarIntegracao(eventoId, participanteId, tipo) {
        return axiosJwt.patch(`${urlBase}/${eventoId}/participantes/${participanteId}/reiniciarintegracao/${tipo}`);
    },

    reiniciarIntegracaoEvento(eventoId, participanteId) {
        return axiosJwt.patch(`${urlBase}/${eventoId}/participantes/${participanteId}/reiniciarintegracaoevento`);
    },

    reiniciarIntegracaoEmLote(eventoId, participantesId, tipo) {
        let dto = {
            participantesId: participantesId,
            tipo: tipo
        };
        return axiosJwt.post(`${urlBase}/${eventoId}/participantes/reiniciarintegracaoemlote`, dto);
    },

    reiniciarIntegracaoEventoEmLote(eventoId, participantesId) {
        let dto = {
            participantesId: participantesId,
        };
        return axiosJwt.post(`${urlBase}/${eventoId}/participantes/reiniciarintegracaoeventoemlote`, dto);
    },

    reiniciarIntegracaoEventoComoPfEmLote(eventoId, participantesId) {
        let dto = {
            participantesId: participantesId,
        };
        return axiosJwt.post(`${urlBase}/${eventoId}/participantes/reiniciarintegracaoeventocomopfemlote`, dto);
    },

    processarAusentes(eventoId) {
        return axiosJwt.patch(`${urlBase}/${eventoId}/participantes/processarausentes`);
    },

    reprocessarParticipantes(eventoId, participantesId) {
        let dto = {
            participantesId: participantesId,
        };
        return axiosJwt.post(`${urlBase}/${eventoId}/participantes/reprocessarparticipantes`, dto);
    },

    obterTabelaErrosParticipante(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/participantes/listaErrosDashboard`);
    },
};