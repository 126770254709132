<template>
    <detalhe titulo="Status" :size="size">
        <status :status="participante?.statusIntegracaoInternaPfDescr"></status>
    </detalhe>
    <detalhe titulo="Código" :size="size">
        {{ participante?.codigoInternoPf }}
    </detalhe>
    <detalhe titulo="Tentativas Integração" :size="size">
        {{ participante?.tentativaIntegracaoInternaPf }}
    </detalhe>
    <detalhe titulo="Observações" :size="size">
        {{ participante?.observacoesIntegracaoInternaPf }}
    </detalhe>
    <detalhe titulo="Data Integração" :size="size">
        {{ $dateFormat(participante?.dataIntegracaoInternaPf, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Objeto Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoIntegracaoInternaPf)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoIntegracaoInternaPf && participante?.objetoIntegracaoInternaPf?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoIntegracaoInternaPf && !participante?.objetoIntegracaoInternaPf?.isJson()">
            {{ participante?.objetoIntegracaoInternaPf }}</span
        >
    </detalhe>
    <detalhe titulo="Retorno Integração" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoRetornoIntegracaoInternaPf)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoRetornoIntegracaoInternaPf && participante?.objetoRetornoIntegracaoInternaPf?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoRetornoIntegracaoInternaPf && !participante?.objetoRetornoIntegracaoInternaPf?.isJson()">
            {{ participante?.objetoRetornoIntegracaoInternaPf }}</span
        >
    </detalhe>
    <detalhe titulo="Sucesso Atualização" :size="size">
        <status :status="participante?.atualizacaoInternaOk ? 'SIM' : 'NÃO'"></status>
    </detalhe>
    <detalhe titulo="Objeto Atualização" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.objetoAtualizacaoInternaPf)"
            :showDoubleQuotes="true"
            v-if="participante?.objetoAtualizacaoInternaPf && participante?.objetoAtualizacaoInternaPf?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.objetoAtualizacaoInternaPf && !participante?.objetoAtualizacaoInternaPf?.isJson()">
            {{ participante?.objetoAtualizacaoInternaPf }}</span
        >
    </detalhe>
    <detalhe titulo="Retorno Atualização" :size="size">
        <vue-json-pretty
            :data="JSON.parse(participante?.retornoAtualizacaoInternaPf)"
            :showDoubleQuotes="true"
            v-if="participante?.retornoAtualizacaoInternaPf && participante?.retornoAtualizacaoInternaPf?.isJson()"
        ></vue-json-pretty>
        <span v-if="participante?.retornoAtualizacaoInternaPf && !participante?.retornoAtualizacaoInternaPf?.isJson()">
            {{ participante?.retornoAtualizacaoInternaPf }}</span
        >
    </detalhe>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        VueJsonPretty,
    },

    props: {
        participante: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
