export const StatusIntegracaoInterna = {
    AGUARDANDO_INTEGRACAO: 0,
    INTEGRADO: 1,
    FALHA_NA_INTEGRACAO: 2,
    ERRO_NA_INTEGRACAO: 3,
    NAO_APLICAVEL: 4
};

export const StatusIntegracaoExterna = {
    INVALIDO: 0,
    VALIDO: 1
};